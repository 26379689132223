<script setup lang="ts">

</script>

<template>
  <el-card class="card">
    <template #header>
      <div class="card-header">
        <el-text class="card-header-text">关于我们</el-text>
      </div>
    </template>
    <el-text>
      仙宫矿艺为西安工业大学Minecraft玩家与2021年11月2日自主成立的一个Minecraft组织，暂无计划申报社团，拥有生电/模组/建筑三个服务器，建筑服目前工程为校园复原计划。
    </el-text>
  </el-card>
</template>

<style scoped lang="scss">
.card {
  margin: 15px;
}

.card-header {
  text-align: center;
}

.card-header-text {
  font-size: 30px;
}
</style>